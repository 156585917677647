$(function() {
  
  // Generate Tree
  $('#generate_tree').on('click', function() {
    var tree = [];
    
    // Get Tree Data
    $.ajax({
      url: '/api/emulator/create_tree',
      method: 'POST',
      success: function(res) {
        $('#family_tree').html('');
        
        // Render Great Grandparents
        var ggparent_html = '<div class="flex justify-around mb-4">';
        $.each(res.tree, function(i, v) {
          if (i <= 7) {
            ggparent_html += renderGGParent(v.id, v.gender, v.trait);
          }
        });
        ggparent_html += '</div>';
        $('#family_tree').append(ggparent_html);

        // Render Grandparents
        var gparent_html = '<div class="flex justify-around mb-4">';
        $.each(res.tree, function(i, v) {
          if (i >= 8 && i <= 11) {
            gparent_html += renderGParent(v.id, v.gender, v.trait);
          }
        });
        gparent_html += '</div>';
        $('#family_tree').append(gparent_html);

        // Render Parents
        var parent_html = '<div class="flex justify-around mb-4">';
        $.each(res.tree, function(i, v) {
          if (i >= 12 && i <= 13) {
            parent_html += renderParent(v.id, v.gender, v.trait);
          }
        });
        parent_html += '</div>';
        $('#family_tree').append(parent_html);

        // Render Offspring
        var offspring_html = '<div class="flex justify-around mb-4">';
        $.each(res.tree, function(i, v) {
          if (i == 14) {
            offspring_html += renderOffspring(v.id, v.gender, v.trait);
          }
        });
        offspring_html += '</div>';
        $('#family_tree').append(offspring_html);

      }
    });

  });

  function renderGGParent(id, gender, trait) {
    var html = '<div class="flex-1 mr-3 bg-white rounded-md p-3">';
    if (gender == 0) {
      html += '<h3 class="text-lg font-bold text-[#c100c3]">Great Grandmother</h3>';
    } else {
      html += '<h3 class="text-lg font-bold text-[#0174af]">Great Grandfather</h3>';
    }
    html += '<div class="flex flex-row"><strong>ID:</strong><span class="ml-2">' + id + '</span></div>';
    html += '<div class="flex flex-row"><strong>Trait:</strong><span class="ml-2">' + trait + '</span></div>';
    html += '</div>';
    return html;
  }

  function renderGParent(id, gender, trait) {
    var html = '<div class="flex-1 max-w-[12rem] mr-3 bg-white rounded-md p-3">';
    if (gender == 0) {
      html += '<h3 class="text-lg font-bold text-[#c100c3]">Grandmother</h3>';
    } else {
      html += '<h3 class="text-lg font-bold text-[#0174af]">Grandfather</h3>';
    }
    html += '<div class="flex flex-row"><strong>ID:</strong><span class="ml-2">' + id + '</span></div>';
    html += '<div class="flex flex-row"><strong>Trait:</strong><span class="ml-2">' + trait + '</span></div>';
    html += '</div>';
    return html;
  }

  function renderParent(id, gender, trait) {
    var html = '<div class="flex-1 max-w-[12rem] mr-3 bg-white rounded-md p-3">';
    if (gender == 0) {
      html += '<h3 class="text-lg font-bold text-[#c100c3]">Mother</h3>';
    } else {
      html += '<h3 class="text-lg font-bold text-[#0174af]">Father</h3>';
    }
    html += '<div class="flex flex-row"><strong>ID:</strong><span class="ml-2">' + id + '</span></div>';
    html += '<div class="flex flex-row"><strong>Trait:</strong><span class="ml-2">' + trait + '</span></div>';
    html += '</div>';
    return html;
  }

  function renderOffspring(id, gender, trait) {
    var html = '<div class="flex-1 max-w-[12rem] mr-3 bg-white rounded-md p-3">';
    if (gender == 0) {
      html += '<h3 class="text-lg font-bold text-[#c100c3]">Offspring</h3>';
    } else {
      html += '<h3 class="text-lg font-bold text-[#0174af]">Offspring</h3>';
    }
    html += '<div class="flex flex-row"><strong>ID:</strong><span class="ml-2">' + id + '</span></div>';
    html += '<div class="flex flex-row"><strong>Trait:</strong><span class="ml-2">' + trait + '</span></div>';
    html += '</div>';
    return html;
  }

});