$(document).on('turbo:load', function() {

  // Nav Dropdown
  $('.dropdown .dropdown-toggle').on('click', function(e) {
    e.preventDefault();
    $('.sub-menu:visible').slideUp();
    if ($(this).next('.sub-menu').is(':visible')) {
      $(this).next('.sub-menu').slideUp();
    } else {
      $(this).next('.sub-menu').slideDown();
    }
  });

  $('body').on('click', '.toggle_mobile_nav', function(e) {
    e.preventDefault();
    if ($('.mobile_dropdown_menu').is(':visible')) {
      $('.mobile_dropdown_menu').slideUp();
    } else {
      $('.mobile_dropdown_menu').slideDown();
    }
  });

});