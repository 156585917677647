$(document).on('turbo:load', function() {

  // FAQ Accordion Functionality
  $('.accordion').on('click', function() {
    // Hide all other panels
    $('.panel').addClass('hidden');
    $('.category-arrow').removeClass('rotate-180');
    // If the panel is already active, hide it
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $('.panel').addClass('hidden');
      $('.category-arrow').removeClass('rotate-180');
    } else {
      // Show the panel that was clicked
      $(this).addClass('active');
      $(this).next('.panel').toggleClass('hidden');
      $(this).find('.category-arrow').toggleClass('rotate-180');
    }
  });

});